import React from "react"
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"
import Card from "../Card/Card"
import CardHeader from "../Card/CardHeader"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography";

const style = {
  card: {
    width: "100%",
    marginTop: "40px",
    marginBottom: "50px",
  },
  textCenter: {
    textAlign: "center",
  },
  cardHeader: {
    padding: "15px",
    background: "#eee",
      // "linear-gradient(60deg, rgba(38,198,218, 0.9), rgba(89,135,161, 0.9))",
    boxShadow: "none",
    marginBottom: "15px"
  },
}

const Features = (props) => {
  const { features, title } = props
  const columns = features.length

  return (
    <Card plain style={style.card}>
      <CardHeader noShadow style={style.cardHeader}>
        <Typography variant="h6">
          {title}
        </Typography>
      </CardHeader>

      <GridContainer spacing={0}>
        {features &&
          features.map((items, idx) => (
            <GridItem key={idx} md={12 / columns} xs={12}>
              <List component="nav">
                {items.map((item, idx) => (
                  <ListItem key={idx} style={style.textCenter}>
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
            </GridItem>
          ))}
      </GridContainer>
    </Card>
  )
}

export default Features
