import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SidebarPostInfo from "../components/SidebarPostInfo"
import Comments from "../components/Comments"
import ListingDetails from "../components/ListingDetails"
import { CloudinaryMediaCarousel } from "../components/CloudinaryMedia"
import AgileInquiry from "../components/AgileCRM"
import {
  numberToCurrency,
  splitToChunks,
  getYoutubeEmbed,
} from "../helpers/common"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery"
// Material UI Kit Components
import GridContainer from "../components/Grid/GridContainer"
import GridItem from "../components/Grid/GridItem"
import Card from "../components/Card/Card"
import CardBody from "../components/Card/CardBody"
import CardHeader from "../components/Card/CardHeader"
import carouselStyle from "../assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle.jsx"
import imagesStyles from "../assets/jss/material-kit-pro-react/imagesStyles"
import Iframe from "react-iframe"
import Inquiry from "../components/Inquiry"
import Typography from "@material-ui/core/Typography"

const style = {
  ...carouselStyle,
  ...withStyles,
  ...imagesStyles,
  fullWidth: {
    width: "100%",
    height: "auto",
  },
  carousel: {
    borderRadius: "6px",
    overflow: "hidden",
  },
  cardHeader: {
    marginTop: "0px",
    padding: "0",
    textAlign: "center",
  },
  price: {
    color: "#00acc1",
  },
  description: {
    margin: "20px 0",
    borderRadius: "3px",
    // marginBottom: "0",
  },
  labels: {
    fontWeight: "bold",
  },
  descriptionHeader: {
    padding: "15px",
    background: "#eee",
    boxShadow: "none",
    marginBottom: "15px",
  },
}

function ListingBoat({ data, ...props }) {
  const isMobile = useMediaQuery("(max-width:768px)")
  const {
    pageContext: {
      pageData: {
        avatar,
        created,
        body,
        title,
        drupalId,
        type,
        fieldImage,
        fieldLike,
        relationships,
        drupalInternalNid,
      },
    },
  } = props

  const details = data.allNodeListing.edges[0].node
  const {
    field_condition,
    field_electronics,
    field_engine_brand,
    field_engine_count,
    field_engine_hours,
    field_engine_horsepower,
    field_engine_model,
    field_engine_type,
    field_engine_year,
    field_features,
    field_for_sale_by,
    field_freshwater_capacity,
    field_fuel_capacity,
    field_fuel_type,
    field_hull_type,
    field_length_overall,
    field_beam,
    field_manufacturer,
    field_model,
    field_price,
    field_trailer,
    field_year,
    field_youtube_link,
    field_address,
    field_type,
  } = details

  const price = numberToCurrency(parseFloat(field_price), "$")

  const labeled = (label, data) => {
    return (
      <>
        <span style={style.labels}>{label}:</span> {data}
      </>
    )
  }

  const MapLink = (props) => {
    const { address } = props
    const gMap = "https://www.google.com/maps/place/"
    const link = `<a href="${
      gMap + address.replace(" ", "+")
    }" target="_blank">${address}</a>`
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: link,
        }}
      />
    )
  }

  const general = [
    labeled("Year", field_year),
    labeled("Condition", field_condition),
    labeled("Manufacturer", field_manufacturer),
    labeled("Model", field_model),
    labeled(
      "Price",
      field_price > 0 ? (
        <>
          {price}
          <br />
          <AgileInquiry
            price={field_price}
            title={title}
            url={props.location.href}
            type="text"
          />
        </>
      ) : (
        <Inquiry title={title} id={drupalId} type="text" />
      )
    ),
    labeled("Vessel Type", field_type),
    labeled("For Sale By", field_for_sale_by),
    labeled("Trailer", field_trailer),
    labeled("Location", <MapLink address={field_address} />),
    labeled("ID", drupalInternalNid),
  ]

  const specs = [
    labeled("Length Overall (LOA)", field_length_overall + "'"),
    labeled("Beam", field_beam),
    labeled("Hull Type", field_hull_type),
    labeled("Fuel Type", field_fuel_type),
    labeled("Fuel Capacity (gal)", field_fuel_capacity),
    labeled("Fresh Water Capacity (gal)", field_freshwater_capacity),
  ]

  const propulsion = [
    labeled("Engine Year", field_engine_year),
    labeled("Engine Make", field_engine_brand),
    labeled("Engine Model", field_engine_model),
    labeled("Engine Type", field_engine_type),
    labeled("Engine Hours", field_engine_hours),
    labeled("Engine Total", field_engine_count),
    labeled("Engine Horsepower", field_engine_horsepower),
    labeled("Total Horsepower", field_engine_horsepower * field_engine_count),
  ]

  const CustomHeader = `h${isMobile ? 3 : 2}`

  const CustomTitle = () => {
    return (
      <CustomHeader>
        {field_price > 0 ? (
          <>
            {title} -{" "}
            <span style={style.price}>
              {price}
              <AgileInquiry
                price={field_price}
                title={title}
                url={props.location.href}
              />
            </span>
          </>
        ) : (
          <>{title}</>
        )}
      </CustomHeader>
    )
  }

  const video = () => {
    if (field_youtube_link) {
      const url = getYoutubeEmbed(field_youtube_link)
      if (url) {
        return (
          <div className={"embed-responsive embed-responsive-16by9"}>
            <Iframe
              className={"embed-responsive-item"}
              url={url}
              id={field_youtube_link}
              allowFullScreen
            />
          </div>
        )
      }
    }
    return ""
  }

  const Sidebar = () => (
    <SidebarPostInfo
      postId={drupalInternalNid}
      uuid={drupalId}
      type={type}
      created={created}
      authorName={relationships.uid.name}
      authorImage={avatar}
      fieldLike={fieldLike}
    />
  )

  return (
    <Layout {...props}>
      <div style={style.root}>
        <GridContainer
          id={"sidebar"}
          spacing={3}
          direction="row"
          justify="center"
          alignContent="center"
        >
          {!isMobile && (
            <GridItem xs={12} md={2}>
              <GridContainer
                spacing={4}
                direction="row"
                justify="center"
                alignContent="center"
              >
                <GridItem xs={12}>
                  <Sidebar />
                </GridItem>
                <GridItem style={{ textAlign: "center" }} xs={12}>
                  <Inquiry
                    title={title}
                    id={drupalId}
                    style={{ paddingTop: "10px" }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          )}
          <GridItem id={"card-wrapper"} xs={12} md={10}>
            <Card
              id={"card"}
              style={
                isMobile
                  ? { ...style.carousel, ...style.cardHeader }
                  : style.carousel
              }
              xs={12}
              sm={12}
              md={8}
              lg={8}
            >
              <CardHeader style={style.cardHeader}>
                <CustomTitle />
              </CardHeader>
              <div id={"carousel-wrapper"}>
                <CloudinaryMediaCarousel
                  imageArray={fieldImage}
                  transformations={
                    isMobile
                      ? "q_auto,f_auto,dpr_2.0,w_1024,h_576,c_limit"
                      : "q_auto,f_auto,dpr_2.0,w_1024,h_576,c_pad"
                  }
                  progressive={
                    isMobile ? "w_1024,h_576,c_limit" : "w_1024,h_576,c_pad"
                  }
                />
                {isMobile && <Sidebar />}
                {isMobile && <Inquiry title={title} id={drupalId} />}
              </div>
              <CardBody>
                <ListingDetails
                  title="General Information"
                  features={splitToChunks(general)}
                />
                <ListingDetails
                  title="Specifications"
                  features={splitToChunks(specs)}
                />
                {field_engine_count > 0 && (
                  <ListingDetails
                    title="Propulsion"
                    features={splitToChunks(propulsion)}
                  />
                )}
                {field_electronics.length > 0 && (
                  <ListingDetails
                    title="Electronics"
                    features={splitToChunks(field_electronics)}
                  />
                )}
                {field_features.length > 0 && (
                  <ListingDetails
                    title="Features"
                    features={splitToChunks(field_features)}
                  />
                )}
                <Card plain>
                  <CardHeader style={style.descriptionHeader}>
                    <Typography variant="h6">Description</Typography>
                  </CardHeader>
                  <CardBody>
                    {video()}
                    <p
                      style={style.description}
                      dangerouslySetInnerHTML={{
                        __html: body,
                      }}
                    />
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
            <div style={{ padding: "10px" }}>
              <Comments id={drupalId} type={type} />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($alias: String!) {
    allNodeListing(filter: { path: { alias: { eq: $alias } } }) {
      edges {
        node {
          field_condition
          field_electronics
          field_engine_brand
          field_engine_count
          field_engine_horsepower
          field_engine_hours
          field_engine_type
          field_engine_model
          field_engine_year
          field_features
          field_for_sale_by
          field_freshwater_capacity
          field_fuel_capacity
          field_fuel_type
          field_hull_type
          field_length_overall
          field_beam
          field_manufacturer
          field_price
          field_trailer
          field_year
          field_youtube_link
          field_model
          field_address
          field_type
        }
      }
    }
  }
`

export default ListingBoat
