import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import useMediaQuery from "@material-ui/core/useMediaQuery"
// @material-ui/icons
import Close from "@material-ui/icons/Close"
// core components
import Button from "../CustomButtons/Button"
// Styling
import modalStyle from "../../assets/jss/material-kit-pro-react/modalStyle"
import useTheme from "@material-ui/core/styles/useTheme"
import InquiryForm from "./InquiryForm"
import { numberToCurrency } from "../../helpers/common"

const style = theme => ({
  ...modalStyle(theme),
  modalRootLocal: {
    "& > div:first-child": {
      display: "none",
    },
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  cta: {
    color: "#ffffff",
    backgroundColor: "red",
    padding: "6px",
    borderRadius: "3px",
  },
})

const useStyles = makeStyles(style)

const AgileInquiry = props => {
  const { title, price, url, type = "button" } = props
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState(null)
  const deal = {
    amountValue: price,
    yearsValue: 0,
    downPaymentValue: 0,
  }

  const successMessage =
    "<h4>Thank you for submitting a request for financing! We will be in contact with you very soon to get the process started! Thank you for trusting us with the opportunity to help you with your boating journey!</h4>" +
    "<p>This message will close automatically in five seconds</p>"

  const success = () => {
    setMessage(successMessage)
    setTimeout(() => {
      setOpen(false)
      setMessage(null)
    }, 5000)
  }

  const calculateMonthlyRepayment = () => {
    const downPayment = price * 0.2
    const amountBorrowed = price - downPayment
    const monthlyRate = 0.0499 / 12
    const factor = Math.pow(monthlyRate + 1, 20 * 12)
    const numerator = monthlyRate * factor
    const denominator = factor - 1
    const quotient = numerator / denominator
    const payment = amountBorrowed * quotient
    // return payment.toFixed(2);
    return numberToCurrency(payment, "$")
  }

  return (
    <div style={{ display: type === "button" ? "unset" : "inline-block" }}>
      <div onClick={() => setOpen(true)}>
        {type === "button" ? (
          <span>
            <p style={{lineHeight: "8px", margin: "8px"}}>
                <FontAwesomeIcon color={"grey"} icon={["fas", "calculator"]} />{" "}
                Payments as low as {calculateMonthlyRepayment()}/mo.
            </p>
            <Button color="danger">
              Click to quote now!
            </Button>
          </span>
        ) : (
          <span>
            <small>
              <FontAwesomeIcon color={"grey"} icon={["fas", "calculator"]} />{" "}
              Payments as low as {calculateMonthlyRepayment()}/mo.
            </small>
            <br />
            <a href="#" onClick={e => e.preventDefault()}>
              <small
                style={{
                  color: "#ffffff",
                  backgroundColor: "red",
                  padding: "6px",
                  borderRadius: "3px",
                }}
              >
                Click to Quote Now!
              </small>
            </a>
          </span>
        )}
      </div>
      {open && (
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth={"sm"}
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={() => {
                setOpen(false)
                setMessage(null)
              }}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>Finance Inquiry Form</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            {message ? (
              <p dangerouslySetInnerHTML={{ __html: message }} />
            ) : (
              <InquiryForm
                title={title}
                url={url}
                success={success}
                deal={deal}
              />
            )}
          </DialogContent>
          <DialogActions className={classes.modalFooter} />
        </Dialog>
      )}
    </div>
  )
}

export default AgileInquiry
